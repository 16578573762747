import api from 'src/api/public-api';
import type { UnifiedMembership } from 'src/api/public/orgs';
import type { Provider } from 'src/entries/admin/providers/models';
import type { AssociationMembership } from 'src/types/proto/brokers';
import type { MlsMembership } from 'src/types/proto/integrations';
import { VerificationRequestOrgKind as OrgKind } from 'src/types/proto/verifications';

const RECOMMENDED_PROVIDERS_MAX_SIZE = 4;
const ASSOCIATIONS_DEFAULT_PAGE_SIZE = 500;

interface ExtraProperty {
  // eslint-disable-next-line camelcase
  org_kind: OrgKind;
  isVerified: boolean;
  isVerificationLapsed?: boolean;
}

export type EnrichedAssociation = Provider & ExtraProperty;

const formatOrganizations = async (
  data: Awaited<ReturnType<typeof api.orgs.listOrganizations>>['data']
): Promise<EnrichedAssociation[]> => {
  const organizations: EnrichedAssociation[] = [];

  const orgKindMap = {
    broker_associations: OrgKind.BROKER,
    mls_associations: OrgKind.MLS,
    municipality_associations: OrgKind.MUNICIPALITY,
    real_estate_brokerage: OrgKind.REAL_ESTATE_BROKERAGE,
  };

  const { data: ownAssociations } = await api.orgs.listOwnOrganizations();

  const membershipMap: Record<string, UnifiedMembership> = {};

  const kinds: (keyof typeof ownAssociations)[] = [
    'broker_associations',
    'mls_associations',
    'municipality_associations',
    'real_estate_brokerage',
  ];

  kinds.forEach((kind) => {
    ownAssociations[kind].forEach((a) => {
      if (kind === 'broker_associations') {
        membershipMap[(a as AssociationMembership).associationId] = a;
      } else {
        membershipMap[(a as MlsMembership).mlsOrgId] = a;
      }
    });
  });

  kinds.forEach((kind) => {
    data[kind]?.forEach((assoc) => {
      const membership = membershipMap[assoc.id];
      organizations.push({
        ...assoc,
        org_kind: orgKindMap[kind],
        isVerified: membership?.status === 'VERIFIED',
        isVerificationLapsed: membership && membership.status !== 'VERIFIED',
      } as EnrichedAssociation);
    });
  });

  return organizations;
};

export const getAssociations = async (
  params: Parameters<typeof api.orgs.listOrganizations>[0]
): Promise<EnrichedAssociation[]> => {
  const { data } = await api.orgs.listOrganizations({
    limit: ASSOCIATIONS_DEFAULT_PAGE_SIZE,
    ...params,
  });
  const organizations = await formatOrganizations(data);
  return organizations;
};

const getRecommendedSlots = (
  organizations: EnrichedAssociation[]
): EnrichedAssociation[] => {
  const sortSlots = (a: EnrichedAssociation, b: EnrichedAssociation) =>
    +b.isVerified - +a.isVerified ||
    a.title.toLowerCase().localeCompare(b.title.toLowerCase());

  return organizations.sort(sortSlots).slice(0, RECOMMENDED_PROVIDERS_MAX_SIZE);
};

export const getRecommendedAssociations = async (
  params: Parameters<typeof api.orgs.recommendOrganizations>[0]
): Promise<EnrichedAssociation[]> => {
  const { data } = await api.orgs.recommendOrganizations(params);
  const organizations = await formatOrganizations(data);

  return getRecommendedSlots(organizations);
};

interface GetRecommendedAssociationsStates {
  transactionAddressState?: string;
  recommendedAssociations?: EnrichedAssociation[];
}

export const getRecommendedAssociationsStates = async ({
  transactionAddressState,
  recommendedAssociations,
}: GetRecommendedAssociationsStates): Promise<string[]> => {
  if (!recommendedAssociations) {
    const { data } = await api.orgs.recommendOrganizations({
      fallbackState: transactionAddressState,
      includeFormCount: true,
    });
    // eslint-disable-next-line no-param-reassign
    recommendedAssociations = await formatOrganizations(data);
  }

  const defaultSelectedStates = Array.from(
    new Set(recommendedAssociations.map((a) => a.state).filter(Boolean))
  );

  return defaultSelectedStates;
};
