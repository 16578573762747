/* eslint-disable no-lonely-if */
import React from 'react';
import AssociationSearchModal from 'src/components/common/uploader/association-search-modal';
import type { EnrichedAssociation } from 'src/components/common/uploader/get-associations';
import {
  openAddFormLibrariesModal,
  openConnectFormProvidersModal,
} from 'src/components/common/uploader/library-upload-modals';
import type AccountStore from 'src/stores/account-store';
import type FeaturesStore from 'src/stores/features-store';
import type UiStore from 'src/stores/ui-store';
import type TransactionStore from 'src/models/transactions/transaction';

export interface ProviderModalOptions {
  account: AccountStore;
  features: FeaturesStore;
  ui: UiStore;
  /* Custom text for default button */
  okText?: string;
  /* when <AssociationSearchModal /> is not in caller component, ui.setCustomModal will be used */
  uiCustomModal?: boolean;
  fullListOnly?: boolean;
  transaction?: TransactionStore;
}

export function showRecommendedProviders(
  options: ProviderModalOptions
): boolean {
  const { account, features, ui, transaction } = options;

  if (transaction?.isTemplate) {
    return false;
  }
  if (
    !ui.isEmbedded ||
    !features.membershipVerificationEnabled ||
    !features.associationRecommendationsEnabled
  ) {
    return false;
  }
  const { ownAssociations } = account;
  if (!ownAssociations) {
    return false;
  }
  const hideBannerProviderKinds = [
    'broker_associations',
    'mls_associations',
    'municipality_associations',
  ] as const;
  return !hideBannerProviderKinds.some(
    (x) => (ownAssociations[x] || []).length
  );
}

export async function promoteRecommendedProviders(
  options: ProviderModalOptions
) {
  const { account, ui } = options;

  if (
    showRecommendedProviders(options) &&
    !account.config.seenAddFormLibrariesModal
  ) {
    await openProvidersModal(options);
    ui.updateSeenAddFormLibrariesModal();
  }
}

/**
 * Unified opener for adding form library providers, showing `Recommended Providers` or `Full Provider List` modal
 */
export async function openProvidersModal(options: ProviderModalOptions) {
  const {
    account,
    features,
    ui,
    okText,
    uiCustomModal: useCustomModal = true,
    fullListOnly = false,
  } = options;

  const showRecommended = showRecommendedProviders(options) && !fullListOnly;

  if (ui.isEmbedded) {
    // Use TM modals for embbeded experience
    if (showRecommended) {
      const result = await openAddFormLibrariesModal({
        account,
        ui,
        features,
        okText,
      });
      if (result?.connectedOrganizations?.length) {
        ui.setShouldTMForceLoadForms(true);
        account.refreshOwnAssociations();
      }
    } else {
      // Note: this array will be pushed new items when new providers are connected.
      // FIXME: functions should not mutate argument
      const connectedOrganizations: EnrichedAssociation[] = [];
      await openConnectFormProvidersModal({
        account,
        ui,
        features,
        connectedOrganizations,
      });
      if (connectedOrganizations.length > 0) {
        ui.setShouldTMForceLoadForms(true);
        account.fetchMe();
      }
    }
  } else {
    if (showRecommended) {
      account.refreshOwnAssociations();
      ui.setAddFormLibrariesModalProps({
        visible: true,
        okText,
      });
    } else {
      if (useCustomModal) {
        ui.setCustomModal(({ onClose }) => (
          <AssociationSearchModal
            loadForms={() => {
              account.fetchMe();
              ui.forceLoadForms?.();
            }}
            account={account}
            ui={ui}
            onClose={onClose}
            visible
          />
        ));
      } else {
        ui.setAssociationSearchModalProps({ visible: true });
      }
    }
  }
}
