

import React, { Component } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Pagination } from '@uc/cx.react';
import {
  Col,
  Empty,
  Input,
  Modal,
  Row,
  Select,
  type TableColumnType,
} from 'antd';
import classnames from 'classnames';
import AppButton from 'src/components/common/app-button';
import AppLabel from 'src/components/common/app-label';
import AppTable from 'src/components/common/app-table';
import UiStore from 'src/stores/ui-store';
import { getStateFullName, stateCodeNameMap } from 'src/utils/states';
import EntitlementAlertIndicator from './entitlement-alert-indicator';
import { type EnrichedAssociation, getAssociations } from './get-associations';
import handleMembershipVerification from './handle-membership-verification';

const clsPrefix = 'app-association-search-modal';
const STATES = stateCodeNameMap;

interface Props {
  account: any;
  defaultCursor?: number;
  defaultQuery?: string;
  defaultStates?: string[];
  ui: UiStore;
  loadForms: any;
  onClose: any;
  visible: boolean;
}

interface State {
  allAssociations: EnrichedAssociation[];
  searchQuery: string;
  cursor: number;
  states: string[];
}

export default class AssociationSearchModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { defaultStates, defaultQuery, defaultCursor } = props;
    this.state = {
      allAssociations: [],
      searchQuery: defaultQuery ?? '',
      cursor: defaultCursor ?? 0,
      states: Array.from(defaultStates ?? []),
    };
  }

  componentDidMount() {
    this.fetchAssociations();
  }

  fetchAssociations = async () => {
    const allAssociations = await getAssociations({
      states: this.state.states.join(','),
      cursor: this.state.cursor,
    });

    this.setState({
      allAssociations: allAssociations.sort((a, b) => {
        return a.title.localeCompare(b.title);
      }),
    });
  };

  get tableAssociations() {
    const { allAssociations, searchQuery } = this.state;

    return allAssociations.filter(
      (assoc) => assoc.title.toLowerCase().indexOf(searchQuery) >= 0
    );
  }

  handleSearch = (query: string) => {
    this.setState({
      searchQuery: query.toLowerCase(),
    });
  };

  handleStateChange = (value: string[]) => {
    this.setState(
      {
        states: value,
        cursor: 0,
      },
      this.fetchAssociations
    );
  };

  renderAction(td: EnrichedAssociation) {
    const { ui, account } = this.props;
    const lapsed = td.isVerificationLapsed;
    if (td.isVerified || lapsed) {
      return (
        <div
          className={classnames(`${clsPrefix}__added-badge`, {
            [`${clsPrefix}__added-badge--lapsed`]: lapsed,
          })}
        >
          <AppLabel
            type="tag"
            fontColor="black"
            rounded
            color={!lapsed ? AppLabel.COLORS.GREEN : AppLabel.COLORS.YELLOW}
          >
            {lapsed ? 'Lapsed' : 'Added'}
          </AppLabel>
        </div>
      );
    }
    return (
      <AppButton
        className={`${clsPrefix}__add-btn`}
        onClick={() =>
          handleMembershipVerification({
            formLibrary: td,
            ui,
            account,
            onSuccess: () => {
              this.fetchAssociations();
              ui.fetchRecommendedProviders?.();
              account.refreshOwnAssociations();
              this.props.loadForms?.();
            },
          })
        }
        disabled={!td.verificationMethod}
      >
        Add <PlusOutlined />
      </AppButton>
    );
  }

  render() {
    const { onClose, visible } = this.props;
    const titleText = 'Find new associations and MLSs';
    const isScreenXsMinSize = !window.matchMedia('(min-width: 568px)').matches;

    const columns: (TableColumnType<EnrichedAssociation> & {
      // FIXME: Use AppTableColumnType export from app-table component when app-table component is translate to ts.
      minWidth?: number;
    })[] = [
      {
        key: 'association_name',
        title: 'Association name',
        minWidth: 200,
        render: (_val, td) => (
          <div className={`${clsPrefix}__association-name`}>
            {td.title}{' '}
            {td.isVerificationLapsed && (
              <EntitlementAlertIndicator
                className={`${clsPrefix}__association-name__entitlement`}
                onClick={onClose}
              />
            )}
          </div>
        ),
      },
      {
        key: 'state',
        title: 'State',
        minWidth: 150,
        render: (_val, td) => <span>{getStateFullName(td.state)}</span>,
      },
      {
        key: 'add_btn',
        width: isScreenXsMinSize ? 80 : 124,
        fixed: 'right',
        className: `${clsPrefix}__action`,
        render: (_val, td) => this.renderAction(td),
      },
    ];

    return (
      <Modal
        key="compass-associations-modal"
        className={clsPrefix}
        visible={visible}
        title={titleText}
        onCancel={onClose}
        width="100%"
        footer={null}
        maskClosable={false}
      >
        <p className={`${clsPrefix}__title`} hidden>
          <strong>{titleText}</strong>
        </p>
        <p className={`${clsPrefix}__subtitle`}>
          <Row>
            <Col xs={0} sm={12}>
              <span>
                {this.tableAssociations.length} association
                {this.tableAssociations.length === 1 ? '' : 's'}
              </span>
            </Col>
            <Col xs={24} sm={6} className={`${clsPrefix}__state-container`}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="State"
                className={`${clsPrefix}__state`}
                mode="multiple"
                onChange={this.handleStateChange}
                defaultValue={this.state.states}
              >
                {STATES.map((state) => (
                  <Select.Option key={state[0]} value={state[0]}>
                    {state[1]}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={6}>
              <div>
                <Input
                  prefix={<SearchOutlined />}
                  allowClear
                  defaultValue={this.state.searchQuery}
                  placeholder="Search for an association"
                  onChange={(e) => this.handleSearch(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </p>

        <AppTable
          className={`${clsPrefix}__table`}
          rowKey="key"
          columns={columns}
          data={this.tableAssociations}
          locale={{
            emptyText: (
              <Empty description="Please check your search for typos or spelling errors and make sure your filters are correct." />
            ),
          }}
        />
      </Modal>
    );
  }
}
