

import React, { Component } from 'react';
import { Alert, Col, Form, Input, Row } from 'antd';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import api from 'src/api/public-api';
import AppButton from 'src/components/common/app-button';
import AppModal from 'src/components/common/app-modal';
import AssociationLinkedModal from 'src/components/common/uploader/associations-linked-modal';
import ActionableBanner from 'src/components/common/actionable-banner';

const clsPrefix = 'app-nrds-verify-membership-modal';
const propTypes = {
  account: PropTypes.object,
  organization: PropTypes.object,
  ui: PropTypes.object.isRequired,
  loadForms: PropTypes.func,
  onCancel: PropTypes.func,
  showAssociationsModal: PropTypes.func,
  onVerified: PropTypes.func,
};

@inject('ui')
export default class NwmlsVerifyMembershipModal extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    this.state = {
      apiError: null,
      verified: false,
      visible: true,
    };
  }

  async componentDidMount() {
    const nwmlsCreds = await this.props.account.getNwmlsApiCredentials();
    const { nwmlsId, lastName } = nwmlsCreds;

    this.setState({
      nwmlsId,
      lastName,
    });
  }

  showAssociationLinkedModal = (organization, memberships) => {
    const { ui } = this.props;
    const associations = memberships.map((m) => m.association || m.mlsOrg);
    const additionalAssociations = associations.filter(
      (a) => a && a.id !== organization.id
    );
    if (additionalAssociations.length > 0) {
      ui.setCustomModal(({ onClose }) => (
        <AssociationLinkedModal associations={associations} onClose={onClose} />
      ));
    }
  };

  verifyId = async (values) => {
    const { nwmlsId, lastName } = values;

    if (!nwmlsId || !lastName) {
      return;
    }

    const {
      account,
      loadForms,
      organization,
      showAssociationsModal,
      ui,
      onVerified,
    } = this.props;
    try {
      await account.setNwmlsApiCredentials(nwmlsId, lastName);

      const { data: memberships } = await api.orgs.verifyNrdsId(organization);

      this.showAssociationLinkedModal(organization, memberships);

      account.refreshOwnAssociations();

      ui.toast({
        type: 'success',
        message: `Successfully added ${organization.title}`,
      });

      this.setState({
        verified: true,
      });

      if (onVerified) {
        onVerified(organization);
      }

      if (showAssociationsModal) {
        showAssociationsModal(false);
      }

      if (loadForms) {
        loadForms(true);
      }
    } catch (err) {
      this.setState({
        apiError: err.message || 'An unexpected error occurred.',
      });
    }
  };

  render() {
    const { organization, onCancel } = this.props;
    const { nwmlsId, lastName, verified, visible } = this.state;

    return (
      <AppModal
        visible={visible && !verified}
        title="Verify your membership"
        cancelable
        onCancel={() =>
          this.setState({
            visible: false,
          })
        }
        footer={
          <div>
            <AppButton onClick={onCancel}>Cancel</AppButton>
            <AppButton
              type="primary"
              onClick={this.formRef.current?.submit}
              disabled={!nwmlsId || !lastName}
            >
              Verify NWMLS ID
            </AppButton>
          </div>
        }
      >
        {this.state.apiError && (
          <React.Fragment>
            <Alert message={this.state.apiError} type="error" />
            <br />
          </React.Fragment>
        )}
        <p>
          <strong>{organization.title}</strong> wants to verify your membership
          before providing access to their forms.
        </p>
        {organization.customVerificationMessage && (
          <div className={`${clsPrefix}__custom-verification-banner`}>
            <ActionableBanner
              className="margin-bottom"
              description={organization.customVerificationMessage}
            />
          </div>
        )}
        <Form
          onFinish={this.verifyId}
          ref={this.formRef}
          fields={[
            {
              name: ['nwmlsId'],
              value: this.state.nwmlsId,
            },
            {
              name: ['lastName'],
              value: this.state.lastName,
            },
          ]}
        >
          <div className={`${clsPrefix}__nrds-id`}>
            <Row>
              <Col xs={24} sm={12}>
                NWMLS ID
              </Col>
            </Row>
            <Form.Item
              name="nwmlsId"
              rules={[
                {
                  pattern: /^(?:\d*)$/,
                  message: 'NWMLS ID must be numeric.',
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  this.setState({
                    nwmlsId: e.target.value,
                  })
                }
              />
            </Form.Item>
          </div>

          <span>Last name</span>
          <Form.Item name="lastName">
            <Input
              onChange={(e) =>
                this.setState({
                  lastName: e.target.value,
                })
              }
            />
          </Form.Item>
        </Form>
      </AppModal>
    );
  }
}

NwmlsVerifyMembershipModal.propTypes = propTypes;
