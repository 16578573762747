/* eslint-disable, consistent-return, dot-notation, no-return-await, @typescript-eslint/no-non-null-assertion */
import { tmLoadModal } from '@uc-tm/modal-loader';
import type { DataNode } from 'antd/lib/tree';
import axios, { type AxiosResponse } from 'axios';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import api from 'src/api/public-api';
import type { Provider } from 'src/entries/admin/providers/models';
import { showRecommendedProviders } from 'src/entries/app/transactions/transaction/documents/document/associations';
import type AccountStore from 'src/stores/account-store';
import type FeaturesStore from 'src/stores/features-store';
import type routerStore from 'src/stores/router-store';
import type UiStore from 'src/stores/ui-store';
import type { AssociationMembership } from 'src/types/proto/brokers';
import type { MlsMembership } from 'src/types/proto/integrations';
import {
  VerificationMethodKind,
  type VerificationMethod,
  type VerificationRequestOrgKind as OrgKind,
  type VerificationRequest_GAR as VerificationRequestGAR,
} from 'src/types/proto/verifications';
import { stateCodeNameMap } from 'src/utils/states';
import type { DocumentNode } from './external-documents-list';
import {
  getAssociations,
  getRecommendedAssociations,
  getRecommendedAssociationsStates,
  type EnrichedAssociation,
} from './get-associations';

export class CustomNameError extends Error {
  constructor(name: string, message?: string) {
    super(message);
    this.name = name;
  }
}

export const MISSING_CREDENTIALS_ERROR = 'MissingCredentialsError';

type Organization = Provider & {
  // eslint-disable-next-line camelcase
  org_kind?: OrgKind;
  isVerified?: boolean;
  isVerificationLapsed?: boolean;
};

const stateOptions = stateCodeNameMap.map(([value, label]) => ({
  label,
  value,
}));

const stateCodeNames = stateOptions.reduce(
  (acc, { label, value }) => ({ ...acc, [value]: label }),
  {} as Record<string, string>
);

function goToAccountIntegrations(router: routerStore) {
  if (router.route?.name === 'account.integrations') {
    return;
  }
  const back = JSON.stringify(pick(router.hotRoute, ['name', 'params']));
  router.navigate('account.integrations', {
    back,
  });
}

/**
 * Open `Add Forms from Libraries` modal.
 *
 * When the user clicks on the `Add form library` button, close the current modal and open
 * `Add form libraries once for all transactions` modal or `Connect form providers` modal.
 * The current modal is reopened and restores the state after the subsequent actions are performed.
 */
export async function openAddFormsFromLibraryModal(
  options: AddFormsFromLibraryModalOptions
): Promise<AddFormsFromLibraryModalOptions['state']> {
  const { account, ui, features, transactionAddressState } = options;
  const result = await loadAddFormsFromLibraryModal(options);

  /** User clicks 'Review now' in modal--add-forms. */
  if (result?.reason === 'goToAccountIntegrations') {
    goToAccountIntegrations(ui.parent.router);
    return result;
  }

  /** User clicks `Add Form Library` button. */
  if (result?.reason === 'addFormLibrary') {
    const { reason, ...state } = result;

    /** Open `Add form libraries once for all transactions` modal or `Connect form providers` modal. */
    let connectResult:
      | Awaited<ReturnType<typeof openAddFormLibrariesModal>>
      | Awaited<ReturnType<typeof openConnectFormProvidersModal>>
      | undefined;
    if (showRecommendedProviders({ ui, features, account })) {
      connectResult = await openAddFormLibrariesModal({
        account,
        ui,
        features,
        transactionAddressState,
      });
    } else {
      connectResult = await openConnectFormProvidersModal({
        account,
        ui,
        features,
      });

      if (connectResult?.reason === 'goToAccountIntegrations') {
        return undefined;
      }
    }

    const newOrganizationsConnected =
      !!connectResult?.connectedOrganizations?.length;

    if (newOrganizationsConnected) {
      account.refreshOwnAssociations();
    }

    /** If user connect to the new organizations. Force reload the forms tree. */
    return openAddFormsFromLibraryModal({
      ...options,
      state,
      forceFetchTreeData: newOrganizationsConnected,
    });
  }

  return result;
}

/**
 * Open `Add form libraries once for all transactions` modal.
 *
 * When the user clicks on the `Connect` button, verify user's MLS credentials.
 * - If valid, update the connect button status.
 * - If invalid, close the current modal and open the `Verify your membership` modal.
 *    The current modal is reopened after the subsequent actions are performed.
 *
 * When the user clicks on the `Search additional form providers` button,
 * close the current modal and open `Connect form providers` modal.
 * The current modal is reopened after the subsequent actions are performed.
 */
export async function openAddFormLibrariesModal(
  options: AddFormLibrariesModalOptions
): ReturnType<typeof loadAddFormLibrariesModal> {
  const { account, ui, features, transactionAddressState } = options;
  const result = await loadAddFormLibrariesModal(options);

  if (result?.reason) {
    const { reason, selectedItem, connectedOrganizations, ...state } = result;

    /** User clicks `Search additional form providers` button. Open `Connect form providers` modal. */
    if (reason === 'searchFormProviders') {
      await openConnectFormProvidersModal({
        account,
        ui,
        features,
        connectedOrganizations,
        transactionAddressState,
      });
    }

    /** User clicks `Connect` button. If verify failed, open `Verify your membership` modal to ask user provide id and last name. */
    if (reason === 'connect' && selectedItem) {
      await openVerifyMembershipModal({
        account,
        ui,
        state,
        organization: selectedItem.organization,
        connectedOrganizations,
      });
    }

    return openAddFormLibrariesModal({
      ...options,
      connectedOrganizations,
    });
  }

  return result;
}

/**
 * Open `Connect form providers` modal.
 *
 * When the user clicks on the `Connect` button, verify user's MLS credentials.
 * - If valid, update the connect button status.
 * - If invalid, close the current modal and open the `Verify your membership` modal.
 *    The current modal is reopened after the subsequent actions are performed.
 */
export async function openConnectFormProvidersModal(
  options: ConnectFormProvidersModalOptions
) {
  const { account, ui } = options;
  const result = await loadConnectFormProvidersModal(options);

  /** User clicks 'Review now' in modal--connect-form-providers. */
  if (result?.reason === 'goToAccountIntegrations') {
    goToAccountIntegrations(ui.parent.router);
    return result;
  }

  /** User clicks `Connect` button. Open `Verify your membership` modal. */
  if (result?.reason === 'connect' && result?.selectedItem) {
    const { reason, selectedItem, connectedOrganizations, ...state } = result;

    await openVerifyMembershipModal({
      account,
      ui,
      state,
      organization: selectedItem.organization,
      connectedOrganizations,
    });

    await openConnectFormProvidersModal({ ...options, state });
  }

  return result;
}

/**
 * Open `Verify your membership` modal.
 */
export async function openVerifyMembershipModal({
  retrieveSavedCredentials,
  ...options
}: VerifyMembershipModalOptions & { retrieveSavedCredentials?: boolean }) {
  const { organization, account, ui } = options;
  const kind = options.organization?.verificationMethod?.kind || 'NONE';
  if (kind === 'SELF_VERIFICATION') {
    await loadSelfVerificationModal(organization, {
      account,
      ui,
    });
  } else if (retrieveSavedCredentials) {
    const membershipCredentials = await getMembershipCredentials(kind, account);
    await loadVerifyMembershipModal({
      ...options,
      state: membershipCredentials,
    });
  } else {
    await loadVerifyMembershipModal(options);
  }
}

interface BaseFormDocument {
  id: string;
  /** Attached attribute: if the library is available to current user */
  entitled?: boolean;
  /** Only for leaf node */
  tags?: string[];
  isPropertyDoc: boolean;
}

interface FormTreeData extends DataNode, BaseFormDocument {
  key: string;
  subtitle?: string;
  iconName?: 'folder' | 'pdf';
  iconColor?: string;
  children: FormTreeData[];
}

interface AddFormsFromLibraryModalOptions {
  account: AccountStore;
  ui: UiStore;
  features: FeaturesStore;
  transactionAddressState?: string;
  state?: {
    reason?: 'addFormLibrary' | 'goToAccountIntegrations';
    selectedForms: FormTreeData[];
  };
  primaryAgentName?: string;
  forceFetchTreeData?: boolean;
  getDocuments(force?: boolean): Promise<DocumentNode[]>;
  uploadDocuments(documents: DocumentNode[]): Promise<void>;
}

/**
 * Load `Add Forms from Libraries` modal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--add-documents
 */
async function loadAddFormsFromLibraryModal(
  options: AddFormsFromLibraryModalOptions
) {
  const {
    account,
    ui,
    features,
    primaryAgentName,
    forceFetchTreeData,
    getDocuments,
    uploadDocuments,
    ...props
  } = options;

  const title = primaryAgentName
    ? `Add Forms from ${primaryAgentName}'s Libraries`
    : 'Add Forms from Libraries';

  const defaultExpandAll = !ui.isEmbedded;

  const tagFilterGroupOptions = ui.isEmbedded
    ? []
    : [
        {
          label: 'Residential Forms',
          color: 'orange',
          children: [
            { label: 'Purchase Agreements', value: 'residential.purchase' },
            {
              label: 'Purchase Supplements & Addenda',
              value: 'residential.supplements',
            },
            { label: 'Disclosure Forms', value: 'residential.disclosures' },
            { label: 'Listing Agreements', value: 'residential.listing' },
            {
              label: 'New Construction',
              value: 'residential.new_construction',
            },
            {
              label: 'Rental/Lease/Property Mgmt',
              value: 'residential.rental',
            },
            { label: 'Other Agreements', value: 'residential.other' },
          ],
        },
        {
          label: 'Non-Residential Forms',
          color: 'blue',
          children: [
            {
              label: 'Listing & Purchase Agreements',
              value: 'nonresidential.listing_purchase',
            },
            {
              label: 'Exchange Agreements and Lease',
              value: 'nonresidential.exchange',
            },
            {
              label: 'Business Opportunity',
              value: 'nonresidential.opportunity',
            },
          ],
        },
        {
          label: 'Misc',
          color: 'purple',
          children: [
            { label: 'Related to COVID-19', value: 'misc.covid19' },
            { label: 'Office, Admin, Trust Fund', value: 'misc.office' },
          ],
        },
      ];

  const documentsPromise = getDocuments(forceFetchTreeData);

  const getTreeData = async (): Promise<FormTreeData[]> => {
    const documents = await documentsPromise;
    return documents.map(
      (folder) =>
        ({
          key: `folder/${folder.id}`,
          id: folder.id,
          style: ui.isEmbedded ? { fontSize: 16, fontWeight: 500 } : undefined,
          title: folder.name,
          subtitle: ui.isEmbedded
            ? `${folder.children?.length ?? 0} forms`
            : undefined,
          iconName: 'folder',
          iconColor: ui.isEmbedded ? '#000' : undefined,
          entitled: folder.entitled || !features.membershipVerificationEnabled,
          children:
            folder.children?.map(
              (doc) =>
                ({
                  // Avoid duplicated React key for rendering, as antd tree view is flattened
                  key: `docs/${folder.id}/${doc.id}`,
                  id: doc.id,
                  title: doc.name,
                  tags: [...(doc.tags ?? [])],
                } as FormTreeData)
            ) ?? [],
        } as FormTreeData)
    );
  };

  try {
    const result = await tmLoadModal<AddFormsFromLibraryModalOptions['state']>(
      'tm/add-forms/0',
      {
        ...props,
        title,
        defaultExpandAll,
        tagFilterGroupOptions,
        getTreeData,
      }
    );

    if (result?.reason) {
      return result;
    }

    const selectedIds = result?.selectedForms.map((doc) => doc.id);
    if (selectedIds?.length) {
      const documents = await documentsPromise;
      // Avoid duplicated form belongs to multiple libraries
      const selectedDocuments = uniqBy(
        documents
          .flatMap((doc) => doc.children)
          .filter((doc) => selectedIds.includes(doc!.id)) as DocumentNode[],
        (doc) => doc.id
      );
      uploadDocuments(selectedDocuments);
    }

    return result;
  } catch (error) {
    if (error) {
      console.error('Add forms from library error', error);
    }
  }

  return undefined;
}

interface AddFormLibrariesModalOptions {
  account: AccountStore;
  ui: UiStore;
  features: FeaturesStore;
  transactionAddressState?: string;
  state?: {
    reason?: 'searchFormProviders' | 'connect';
    selectedItem?: { key: string; organization: EnrichedAssociation };
    id?: string;
    lastName?: string;
    message?: string;
  };
  okText?: string;
  connectedOrganizations?: Organization[];
}

/**
 * Load `Add form libraries once for all transactions` modal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--add-form-libraries
 */
async function loadAddFormLibrariesModal(
  options: AddFormLibrariesModalOptions
) {
  const {
    account,
    ui,
    features,
    transactionAddressState,
    connectedOrganizations = [],
    ...props
  } = options;

  const organizationToListDataItem = <T extends Organization>(
    organization: T
  ) => ({
    key: organization.id,
    title: organization.title,
    description: `${organization.formCount || 0} forms`,
    checked: organization.isVerified,
    organization,
  });

  const getListData = async () => {
    if (!features.membershipVerificationEnabled) {
      return [];
    }

    const recommendedAssociations = await getRecommendedAssociations({
      fallbackState: transactionAddressState,
      includeFormCount: true,
    });

    const listData = recommendedAssociations.map(organizationToListDataItem);

    return listData;
  };

  const onConnect = async ({
    organization,
  }: {
    organization: EnrichedAssociation;
  }) => {
    const organizations = await verifyOrganization(organization, {
      account,
      ui,
    });
    if (organizations) {
      connectedOrganizations.push(...organizations);
      // If multiple organizations are connected, show summary confirm modal.
      if (organizations.length > 1) {
        await loadVerifyMembershipSummaryModal(organizations);
      }
      return organizations?.map((org) => ({
        ...organizationToListDataItem(org),
        checked: true,
      }));
    }
    return undefined;
  };

  try {
    const result = await tmLoadModal<AddFormLibrariesModalOptions['state']>(
      'tm/add-form-libraries/0',
      {
        ...props,
        getListData,
        onConnect,
        width: 550,
      }
    );

    return { ...result, connectedOrganizations };
  } catch (error) {
    if (error) {
      console.error('Connect form providers error', error);
    }
  }

  return undefined;
}

interface ConnectFormProvidersModalOptions {
  account: AccountStore;
  ui: UiStore;
  features: FeaturesStore;
  transactionAddressState?: string;
  state?: {
    reason?: 'connect' | 'goToAccountIntegrations';
    selectedItem?: { key: string; organization: EnrichedAssociation };
    id?: string;
    lastName?: string;
    message?: string;
    selectedStates?: string[];
    searchKeyword?: string;
  };
  connectedOrganizations?: Organization[];
}

/**
 * Load `Connect form providers` modal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--connect-form-providers
 */
async function loadConnectFormProvidersModal(
  options: ConnectFormProvidersModalOptions
) {
  const {
    account,
    ui,
    features,
    connectedOrganizations = [],
    transactionAddressState,
    ...props
  } = options;

  const stateSelectOptions = stateOptions;

  const organizationToTreeDataItem = <T extends Organization>(
    organization: T
  ) => ({
    key: organization.id,
    name: organization.title,
    state: stateCodeNames[organization.state || ''],
    count: organization.formCount,
    checked: organization.isVerified,
    isVerificationLapsed: organization.isVerificationLapsed,
    organization,
  });

  const getTableData = async ({
    skip = 0,
    limit = 10,
    states,
    search,
  }: GetOrganizationsDataParams) => {
    if (!features.membershipVerificationEnabled) {
      return [];
    }

    // FIXME: Fetch all data and implement pagination at frontend. Since the backend api param `limit` is not works correctly.
    // const organizationsData =
    //   statesString === organizationsDataCache.states
    //     ? organizationsDataCache.data
    //     : await this.getOrganizationsData({
    //         skip,
    //         limit,
    //         states: states?.join(','),
    //       });

    const data = await getOrganizationsData({
      skip,
      limit,
      states: Array.isArray(states) ? states.join(',') : states,
      search,
    });

    const tableData = {
      ...data,
      list: data.list.map(organizationToTreeDataItem),
    };

    return tableData;
  };

  const onConnect = async ({
    organization,
  }: {
    organization: EnrichedAssociation;
  }) => {
    const organizations = await verifyOrganization(organization, {
      account,
      ui,
    });
    if (organizations) {
      connectedOrganizations.push(...organizations);
      // If multiple organizations are connected, show summary confirm modal.
      if (organizations.length > 1) {
        await loadVerifyMembershipSummaryModal(organizations);
      }
      return organizations.map((org) => ({
        ...organizationToTreeDataItem(org),
        checked: true,
      }));
    }
    return undefined;
  };

  try {
    const defaultSelectedStates = await getRecommendedAssociationsStates({
      transactionAddressState,
    });

    const result = await tmLoadModal<ConnectFormProvidersModalOptions['state']>(
      'tm/connect-form-providers/0',
      {
        ...props,
        state: {
          ...props.state,
          selectedStates: props.state?.selectedStates || defaultSelectedStates,
        },
        stateSelectOptions,
        getTableData,
        onConnect,
      }
    );

    return { ...result, connectedOrganizations };
  } catch (error) {
    if (error) {
      console.error('Connect form providers error', error);
    }
  }

  return undefined;
}

interface VerifyMembershipModalOptions {
  account: AccountStore;
  ui: UiStore;
  state?: {
    id?: string;
    lastName?: string;
    errorMessage?: string;
  };
  organization: EnrichedAssociation;
  connectedOrganizations: Organization[];
}

type VerifyParams = Parameters<typeof api.orgs.verifyNrdsId>[1];
const noIdMethodKinds = [
  VerificationMethodKind.NONE,
  VerificationMethodKind.SELF_VERIFICATION,
  VerificationMethodKind.PROVIDER_DEPENDENT,
  VerificationMethodKind.CUSTOM,
  VerificationMethodKind.SSO,
  VerificationMethodKind.PEOPLE_SERVICE,
] as const;
type NoIdMethodKind = typeof noIdMethodKinds[number];
type PickedVerificationMethodKind = Exclude<
  VerificationMethodKind,
  NoIdMethodKind
>;
export type TmVerificationType = 'NRDS' | 'NWMLS' | 'GAR' | 'NJ';

/**
 * Load `Verify your membership` modal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--verify-membership
 */
async function loadVerifyMembershipModal(
  options: VerifyMembershipModalOptions
) {
  const { account, ui, organization, connectedOrganizations, ...props } =
    options;

  const typeMap: Record<PickedVerificationMethodKind, TmVerificationType> = {
    NAR: 'NRDS',
    NWMLS: 'NWMLS',
    // NAR & CAR share the nrdsId + lastName credentials
    CAR: 'NRDS',
    GAR: 'GAR',
    BRIDGE: 'NRDS',
    NJ: 'NJ',
  } as const;

  try {
    const kind = organization?.verificationMethod
      ?.kind as PickedVerificationMethodKind;
    if (!kind) {
      throw new Error('kind does not exist');
    }
    if (!(kind in typeMap)) {
      throw new Error(`${kind} not supported`);
    }

    const result = await tmLoadModal<VerifyMembershipModalOptions['state']>(
      'tm/verify-membership/0',
      {
        ...props,
        type: typeMap[kind],
        organization: {
          name: organization?.title,
          count: organization?.formCount,
          customVerificationMessage: organization?.customVerificationMessage,
        },
        onOk: async ({
          id,
          lastName,
        }: Required<Required<VerifyMembershipModalOptions>['state']>) => {
          try {
            // NOTE: For testing purpose. Verify membership with id `424242424` to see the result modal.
            if (window.Glide.env !== 'production' && id === '424242424') {
              connectedOrganizations.push(organization);
              await loadVerifyMembershipSummaryModal([organization]);
              return { id, lastName, connectedOrganizations };
            }

            const verifyParams: VerifyParams = {};

            switch (kind) {
              // NAR & CAR share the nrdsId + lastName credentials
              case 'NAR':
              case 'CAR':
                await account.setNarApiCredentials(id, lastName);
                break;
              case 'NWMLS':
                await account.setNwmlsApiCredentials(id, lastName);
                break;
              case 'BRIDGE':
                await account.setBridgeRiApiCredentials(id, lastName);
                break;
              case 'NJ':
                await account.setNJApiCredentials(id, lastName);
                break;
              case 'GAR':
                (verifyParams as VerificationRequestGAR).garId = id;
                break;

              default:
            }

            const res = await api.orgs.verifyNrdsId(organization, verifyParams);
            if (res.data?.length > 0) {
              const organizations = res.data.map(
                (membership) =>
                  ((membership as AssociationMembership).association ||
                    (membership as MlsMembership).mlsOrg) as Provider
              );
              connectedOrganizations.push(...organizations);

              if (organizations.some((o) => o && o.id !== organization.id)) {
                await loadVerifyMembershipSummaryModal(organizations);
              }
            }
          } catch (error) {
            if (error instanceof Error) {
              ui.wentWrong(error);
            }
            throw error;
          }

          return undefined;
        },
      }
    );

    return { ...result, connectedOrganizations };
  } catch (error) {
    if (error) {
      console.error('Verify membership error', error);
    }
  }

  return undefined;
}

/**
 * Load confirm modal from Compass CDN by modal-loader. To show the verify membership summary infomation.
 *
 * FIXME: Change backend api to return form count for own organization? Or call api at frontend?
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--confirm
 */
async function loadVerifyMembershipSummaryModal(
  verifiedOrganizations: Organization[]
) {
  let organizations = verifiedOrganizations;
  const libraryUuidsWithoutFormCount = verifiedOrganizations
    .filter((organization) => !Number.isInteger(organization.formCount))
    .map(({ libraryUuid }) => libraryUuid);

  /** If there are some organizations missing form count, query them from backend. */
  if (libraryUuidsWithoutFormCount.length > 0) {
    const { data: libraryCounts }: AxiosResponse<Record<string, number>> =
      await api.forms.getFormCountByLibraryUuids(libraryUuidsWithoutFormCount);
    organizations = organizations.map((organization) => ({
      ...organization,
      formCount: libraryCounts[organization.libraryUuid] || 0,
    }));
  }

  const html = `
    <p>Your membership also gives you access to these additional form libraries:</p>
    ${organizations
      .map(
        ({ title, formCount }) => `
          <div>
            <i style="vertical-align: middle">
              <svg class="cx-icon" fill="var(--cx-color-success, #3aac7f)" width="16" height="16" viewBox="0 0 16 16">
                <use xlink:href="/ucfe-assets/cx-icons/4/cx-icons.cdn.svg#circleWithCheckmark"></use>
              </svg>
            </i>
            <b>${title}</b>
            <span style="color: var(--cx-color-textSubtle, #6c6c6c);">(${formCount} forms)</span>
          </div>
        `
      )
      .join('')}
  `;

  const props = {
    width: 'initial',
    title: 'We’ve found more forms you have access to',
    html,
    okText: 'Done',
    cancelButtonProps: { hidden: true },
  };

  try {
    return await tmLoadModal('tm/confirm/0', props);
  } catch (error) {
    if (error) {
      console.error('Verify membership summary error', error);
    }
  }

  return undefined;
}

/**
 * Load confirm modal from Compass CDN by modal-loader. To show the SELF_VERIFICATION membership verification modal.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--confirm
 */
async function loadSelfVerificationModal(
  organization: EnrichedAssociation,
  { account, ui }: VerifyOrganizationOptions
): Promise<Organization[] | undefined> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const props = {
      width: 520,
      title: 'Certify your membership',
      html: `<div>I certify that I am a member of <b>${organization.title}</b> in good standing and am entitled to access fillable digital forms offered by this form provider.</div>`,
      actions: [
        {
          label: 'No, I am not a member',
          onClick: async () => {
            const isMember = await account.isMemberFromAssociation(
              organization
            );
            if (!isMember) {
              resolve(undefined);
              return;
            }
            await api.orgs.unlinkAssociation(organization);
            ui.toast({
              type: 'success',
              message: `Unlinked ${organization.title}`,
            });

            account.fetchMe();
            account.refreshOwnAssociations();
            resolve(undefined);
          },
        },
        {
          type: 'primary',
          label: 'Yes, certify membership',
          onClick: async () => {
            resolve(await requestVerifyOrganization(organization));
            account.fetchMe();
            account.refreshOwnAssociations();
          },
        },
      ],
    };
    try {
      await tmLoadModal('tm/custom-actions/0', props);
    } catch (error) {
      if (error) {
        console.error('SelfVerification verify membership modal error', error);
      } else {
        resolve(undefined);
      }
    }
  });
}

interface GetOrganizationsDataParams {
  states?: string | string[];
  skip?: number;
  limit?: number;
  search?: string;
}

async function getOrganizationsData(
  {
    states = '',
    skip = 0,
    limit = 10,
    search,
  } = {} as GetOrganizationsDataParams
) {
  let list = await getAssociations({
    states: Array.isArray(states) ? states.join(',') : states,
    cursor: 0,
    includeFormCount: true,
  });

  let total = list.length;

  if (search) {
    list = list.filter((record) =>
      record.title.toUpperCase().includes(search.toUpperCase())
    );
    total = list.length;
  }

  return { total, list: list.slice(skip, skip + limit) };
}

async function requestVerifyOrganization(
  organization: EnrichedAssociation
): Promise<Organization[] | undefined> {
  // NOTE: `verifyNrdsId` is not for `NAR` only, it can be used for verifying other organizations, like `NWMLS` or `CAR`.
  const res = await api.orgs.verifyNrdsId(organization);
  if (!res?.data && res?.status === 200) {
    return [organization];
  }
  return res?.data?.map(
    (membership) =>
      ((membership as AssociationMembership).association ||
        (membership as MlsMembership).mlsOrg) as Provider
  );
}

export interface MembershipCredentials {
  id: string;
  lastName: string;
}

async function getMembershipCredentials(
  kind: VerificationMethodKind,
  account: AccountStore,
  verificationMethod?: VerificationMethod
): Promise<MembershipCredentials> {
  switch (kind) {
    // NAR & CAR share the nrdsId + lastName credentials
    case 'NAR':
    case 'CAR': {
      const res = await account.getNarApiCredentials();
      return {
        id: res.nrdsId,
        lastName: res.lastName,
      };
    }
    case 'NWMLS': {
      const res = await account.getNwmlsApiCredentials();
      return {
        id: res.nwmlsId,
        lastName: res.lastName,
      };
    }
    case 'BRIDGE': {
      const res = await account.getBridgeApiCredentials(
        verificationMethod?.bridge?.bridgeProvider
      );
      return {
        id: res.nrdsId ?? '',
        lastName: res.lastName ?? '',
      };
    }
    case 'NJ': {
      const res = await account.getNJApiCredentials();
      return {
        id: res.njNrdsId,
        lastName: res.lastName,
      };
    }
    default:
  }
  return {
    id: '',
    lastName: '',
  };
}

interface VerifyOrganizationOptions {
  account: AccountStore;
  ui: UiStore;
}

/** Verify an organization base on its `verificationMethod`. The result may be multiple verified organizations. */
export async function verifyOrganization(
  organization: EnrichedAssociation,
  { account, ui }: VerifyOrganizationOptions
): Promise<Organization[] | undefined> {
  const kind = organization.verificationMethod?.kind || 'NONE';
  const membershipCredentials = await getMembershipCredentials(
    kind,
    account,
    organization?.verificationMethod
  );

  try {
    switch (kind) {
      case 'NAR':
      case 'CAR':
      case 'NWMLS':
      case 'BRIDGE': {
        if (!membershipCredentials.id || !membershipCredentials.lastName) {
          throw new CustomNameError(MISSING_CREDENTIALS_ERROR);
        } else {
          return await requestVerifyOrganization(organization);
        }
      }
      case 'NJ': {
        if (!membershipCredentials.id || !membershipCredentials.lastName) {
          throw new CustomNameError(MISSING_CREDENTIALS_ERROR);
        } else {
          return await requestVerifyOrganization(organization);
        }
      }
      case 'SELF_VERIFICATION':
        return loadSelfVerificationModal(organization, {
          account,
          ui,
        });
      default: {
        return await requestVerifyOrganization(organization);
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.name !== MISSING_CREDENTIALS_ERROR) {
        ui.wentWrong(error);
      }
      if (
        (axios.isAxiosError(error) && Number(error.code) < 500) ||
        error.name === MISSING_CREDENTIALS_ERROR
      ) {
        const err = new Error(error.message);

        Object.assign(err, {
          cause: error,
          reason: noIdMethodKinds.includes(kind as any) ? '' : 'connect',
          ...membershipCredentials,
        });
        throw err;
      }
    }
    throw error;
  }

  return undefined;
}
